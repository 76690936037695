<template>
  <div class="d-flex flex-column flex-center flex-column-fluid h-100">
    <div class="d-flex flex-column flex-center text-center p-10">
      <div class="card card-flush w-lg-650px py-5">
        <div class="card-body py-15 py-lg-20">
          <h1 class="fw-bolder fs-2qx text-info mb-4">
            <i class="fas fa-info-circle fs-2qx"></i>
            {{
              $t(
                "BaseModelFields.Information",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h1>
          <div class="fw-semibold fs-5 mb-7">
            <p class="text-start mb-1">
              {{
                $t(
                  "BaseModelFields.PleaseCheckErrorMessagesDescription",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </p>
            <div class="alert alert-warning mb-2 text-start" role="alert">
              <ul class="mb-0">
                <li class="mb-2" v-for="(error, i) in calendarErrors" :key="i">
                  {{ error.message }}
                  <p v-if="error.views" class="mb-0">
                    <span
                      v-for="(item, i) in error.views"
                      :key="i"
                      class="badge badge-danger me-2"
                    >
                      {{ item }}
                    </span>
                  </p>
                </li>
                <li v-if="viewFilterErrors.length > 0">
                  {{
                    $t(
                      "Components.FullCalendar.ViewFilterFieldError",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                  <p class="text-start mb-0 mt-1">
                    <span
                      v-for="(item, i) in viewFilterErrors"
                      :key="i"
                      class="badge badge-danger me-2"
                    >
                      {{ item.name }}
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="mb-0">
            <router-link
              :to="{ name: 'list', params: { key: this.$route.params.key } }"
              class="btn btn-success me-2"
            >
              <i class="fas fa-table"></i>
              {{
                $t(
                  "BaseModelFields.GoToList",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link
            >
            <router-link :to="$appHomeUrl" class="btn btn-primary">
              <i class="bi-app-indicator bi"></i>
              {{
                $t(
                  "BaseModelFields.ReturnHome",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CalendarValidation",
  props: {
    viewFilterErrors: {
      type: Array,
      default() {
        return [];
      },
    },
    calendarErrors: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
